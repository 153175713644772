<template>
    <v-card flat height="470" class="mb-7">
        <v-card-text class="pa-5">
            <div class="d-md-flex align-items-center">
                <div>
                    <span class="lstick"></span>
                    <h3 class="title font-weight-regular">Contactos Recientes</h3>
                </div>
                <div class="ml-auto">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <a href="javascript:void(0)" class="link" dark v-bind="attrs" v-on="on">
                                <i class="fas fa-ellipsis-v"></i>
                            </a>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, index) in items" :key="index">
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>

            <v-list class="mt-4" v-if="!loading">
                <ItemContact v-for="(item, i) in messages" :key="i" @reload="getMessages" :item="item" />
            </v-list>
            <v-virtual-scroll v-else :items="[1, 2, 3]" :item-height="70" height="400">
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
            </v-virtual-scroll>
        </v-card-text>
    </v-card>
</template>

<script>
    import dashboardService from '@/providers/DashboardService';
    import ItemContact from "@/components/dashboards/my-contacts/ItemContact.vue";

    export default {
        name: "TheMyContacts",
        components: {ItemContact},
        data: () => ({
            items: [{title: "Edit"}, {title: "Update"}, {title: "Add"}],
            messages: [],
            loading: true,
        }),
        filters: {
            trimText(value) {
                return value.trim();
            }
        },
        methods: {
            getMessages() {
                this.loading = true;
                dashboardService.getRecientContacts().then(record => {
                    this.messages = record.value;
                }).catch(err=>console.log(err)).finally(()=>{
                    this.loading = false;
                });
            },
        },
        mounted() {
            this.messages = [];
            this.getMessages();
        }
    };
</script>